<template id="loader_box">
    <div id="loader_ImgBox">
        <div id="loader_Text">{{text}}</div>
        <!-- <img id="loader_ImgS" src="@/assets/s.png"> -->

        <!-- Generator: Adobe Illustrator 23.0.3, SVG Export Plug-In  -->
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px"
                height="50px" viewBox="0 0 44.96 56.12" style="enable-background:new 0 0 44.96 56.12; fill:#FFFFFF;display: block;margin: auto; margin-top:39px;" xml:space="preserve">

                <path class="st0" d="M44.91,34.2c0.11,0.81,0,3.28,0,3.28c0,10.29-8.34,18.63-18.63,18.63h-7.6c-10.29,0-18.63-8.34-18.63-18.63
                        v-1.22c0,0,3.84,3.77,9.72,4.99c4.08,0.85,9.72,0.83,9.72,0.83v4.99l11.67-8.42l-11.67-8.42v4.36c0,0-7.45-1-9.72-1.97
                        c-8.44-3.64-9.55-8.97-9.72-10.29c-0.12-0.92,0-3.71,0-3.71C0.05,8.34,8.39,0,18.68,0h7.6c10.29,0,18.63,8.34,18.63,18.63v2.02
                        c-0.7-0.67-3.46-3.2-9.59-4.86c-3.26-0.88-9.72-0.83-9.72-0.83V9.98l-11.67,8.42l11.67,8.42v-4.36c0,0,7.41,1.08,9.72,1.97
                        C43.41,27.55,44.57,31.65,44.91,34.2z"/>
        </svg>
        
        <div class="loader_lds-double-ring">
            <div></div>
        </div>
    </div>
</template>  
<script>
export default {
  props:['text']
}
</script> 
<style scoped>
#loader_ImgBox {
    position: absolute;
    z-index: 20000;
    max-width: 200px;
    max-height: 200px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: rgba(72, 72, 72, 0.2);
    left: calc(50% - 67px);
    top: calc(50% - 67px);
}

#loader_Text{
    width:200px;
    position:absolute;
    top:140px;
    left:-34px;
    text-align:center;
    color:white;
    font-size:1rem;
}

#loader_ImgSky{
    position:absolute;
    width:200px;
    top: 137px;
    left:-34px;
}


#loader_ImgS{
    width: 45px;
    top: 37px;
    left: 41px;
    position: absolute;
}

@keyframes loader_lds-double-ring {
        0% {
                -webkit-transform: rotate(0);
                transform: rotate(0);
        }
        100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}
@-webkit-keyframes loader_lds-double-ring {
        0% {
                -webkit-transform: rotate(0);
                transform: rotate(0);
        }
        100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}
@keyframes loader_lds-double-ring_reverse {
        0% {
                -webkit-transform: rotate(0);
                transform: rotate(0);
        }
        100% {
                -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg);
        }
}
@-webkit-keyframes loader_lds-double-ring_reverse {
        0% {
                -webkit-transform: rotate(0);
                transform: rotate(0);
        }
        100% {
                -webkit-transform: rotate(-360deg);
                transform: rotate(-360deg);
        }
}
.loader_lds-double-ring {
        position: absolute;
        top: -11px;
        left: -10px;
}
.loader_lds-double-ring div {
        position: absolute;
        width: 105px;
        height: 105px;
        top: 23px;
        left: 22px;
        border-radius: 50%;
        border: 6px solid #000;
        border-color: #ffffff transparent #ffffff transparent;
        -webkit-animation: loader_lds-double-ring 1s linear infinite;
        animation: loader_lds-double-ring 1s linear infinite;
}
.loader_lds-double-ring div:nth-child(2) {
        width: 90px;
        height: 90px;
        top: 31px;
        left: 30px;
        border-color: transparent #ffffff transparent #ffffff;
        -webkit-animation: loader_lds-double-ring_reverse 1s linear infinite;
        animation: loader_lds-double-ring_reverse 1s linear infinite;
}
.loader_lds-double-ring {
        width: 100px !important;
        height: 100px !important;
        -webkit-transform: translate(-50px, -50px) scale(1) translate(50px, 50px);
        transform: translate(-50px, -50px) scale(1) translate(50px, 50px);
}

</style>