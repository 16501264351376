<template>
  <div id="tPayContainer">

    <vue-pull-refresh :on-refresh="onRefresh" :config="refreshConfig">


    <div class="headerContainer">
      <img class="logoContainer" src="/img/dolce.jpeg" />
      <div class="nameContainer">Dolce Coffee</div>
    </div>

    <div v-if="tPayId != null && orders.length > 0" class="contentContainer">
      <div v-if="loader == false">
        Заказы
        <div v-for="(item, index) in orders" :key="index">
          <div>{{item}}</div>
        </div>
      </div>
    </div>

    <loader v-if="loader"></loader>
    <div v-if="loader == true" class="contentContainer"></div>

    <div v-if="loader == false && tPayId == null" class="receiptsNotFound">
      <div><strong>QR</strong> код не дійсний,<br> зверніться до офіціанта для здійснення оплати</div>
    </div>

    <div v-if="tPayId != null && orders.length <= 0 && loader == false" class="receiptsNotFound">
      <div>
        <div>Рахунків для оплати не знайдено</div>
        <button
          @click="update"
          style="margin-top: 10px"
          class="btn btn-warning"
        >
          Оновити
        </button>
      </div>
    </div>

    <div class="footerContainer">Powered by Skyservice POS</div>

  
</vue-pull-refresh>


  </div>
</template>

<script>
var socket;
import Vue from "vue";
import Loader from "@/components/Loader.vue"
import VuePullRefresh from 'vue-pull-refresh';
export default {
  name: "tPay",
  data() {
    return {
      tPayId: null,
      orders: [],
      loader:true,
      refreshConfig:{
        "errorLabel": "Не вдалося оновити",
        "startLabel": "Протяніть до низу щоб оновити список замовлень",
        "readyLabel": "Відпустьть щоб оновити",
        "loadingLabel": "Завантаження",
      }
    };
  },
  components:{
    Loader,VuePullRefresh
  },
  
  methods: {
    onRefresh: function() {
      this.orders = [];
      this.update();
    },
    update() {
      this.loader = true;
            setTimeout(()=>{
        //Таймаут 7 сек
        if(this.loader == true){
              this.loader = false
        }
      },7000)

      socket.send(
        JSON.stringify({ "send": "getOrder" })
      );
    },
    connect(tPayId) {
      if(tPayId){
      /*Подключаемся к Websockets*/
      var wsurl = "wss://img-1.skyservice.pro/skysocket-dev/guest/"+tPayId;

      try {
        socket = new window.ReconnectingWebSocket(wsurl);
        socket.onopen = () => {
          //console.log("connected", event);
          setTimeout(()=>{
              this.update();
          },500);
        };

        // socket.onclose = function (event) {
        //   console.log("WebSocket is closed now");
        //   console.log(event.code);
        //   console.log(event.reason);
        // };

        // socket.onerror = function (event) {
        //   console.log("WebSocket error");
        //   console.log(event.code);
        //   console.log(event.reason);
        // };

        socket.onmessage =  (event) => {
          //console.log("ws event:", event);
          var message = JSON.parse(event.data);
          console.log("ws message", message);
          if(message.send == "sendGuest"){
            this.loader = false;
            this.orders.push(message.data)
          }
       
          //app.main.accept(message);
        };

        window.onbeforeunload = function () {
          socket.close();
        };
      } catch (err) {
        console.error(err);
      }


      setTimeout(()=>{
        //Таймаут 7 сек
        if(this.loader == true){
              this.loader = false
        }
      },7000)
      }else{
        this.loader = false
      }

    },
  },
  created() {
    var url = new URL(window.location.href);
    var tPayId = url.searchParams.get("tpayid");
    Vue.set(this, "tPayId", tPayId);
    setTimeout(() => {
      this.connect(tPayId);
    }, 1000);
  },
};
</script>

<style>
#tPayContainer {
  width: 100%;
  height: 100%;
}

.headerContainer {
  width: 100%;
  height: 70px;
  display: flex;
}

.nameContainer {
  padding-top: 22px;
  padding-left: 10px;
  font-size: 16pt;
}

.logoContainer {
  height: 60px;
  margin-top: 5px;
  margin-left: 30px;
}

.contentContainer {
  width: 100%;
  height: calc(100% - 110px);
}

.receiptsNotFound {
  width: 100%;
  height: calc(100% - 110px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerContainer {
  width: 100%;
  height: 40px;
  font-size: 10pt;
  text-align: center;
}

.receiptsNotFound {
  text-align: center;
}
</style>
