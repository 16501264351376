<template>
  <div id="app">
    <tpay msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import tpay from './components/tpay.vue'

export default {
  name: 'App',
  components: {
    tpay
  }
}
</script>

<style>
html, body{
  width:100%;
  height:100%;
  padding:0px;
  margin:0px;
}

#app {
  width:100%;
  height:100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
